.header {
  position: fixed;
  display: flex;
  width: 100%;
  justify-content:space-between;
  align-items: center;
  min-height: 2.5em;
  background-color: rgb(37, 37, 37);
  z-index: 20;
}

.header__link {
  flex: 1 1 50px;
  color: black;
  text-align: center;
  text-decoration: none;
  color: white;
  font-weight: 300;
  font-size: 13px;
  cursor: pointer;
  transition: all .2s;
  &__active {
    font-weight: 500;
    transform: scale(1.2);
  }
}

.header__link:hover {
  transform: scale(1.2);
}

.title-page {
  background-color: rgba(255, 255, 255, 0.452);
  position: absolute;
  padding: 10px;
  width: 98%;
  font-size: 40px;
  font-weight: 300;
  bottom: 150px;
}

.country_chooser {
  top: 120px;
}

