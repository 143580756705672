
.section__wrapper {
  padding-left: 10%;
  padding-right: 10%;
}

.section {
  display: flex;
  padding: 2em 4em;
  background-color: rgb(255, 255, 255);
  min-height: calc(100vh - 65px - 65px);
  transition: all .2s;
  & h3:not(:first-of-type) {
    margin-top: 30px;
  };
  & h3 {
    margin-bottom: 30px;
  }
  & h2 {
    margin-bottom: 20px;
  }
}

.section-home {
  padding: 0;
  background-color: transparent;
  margin-bottom: 40px;
  position: "relative";
}

.section__list {
  text-decoration-style: none;
}

.section__image__home {
  position: fixed;
  background-color:rgb(255, 255, 255);
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url("../images/graphene.png");
  background-size: cover;
}

.section:first-of-type {
  margin-top: 2em;
}

.section:last-of-type {
  margin-bottom: 4em;
}

.section-disclaimer {
  margin-bottom: 4em;
}

