.gototop {
  position: fixed;
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  bottom: 120px;
  right: 10px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, .3);
  color: rgba(37, 37, 37);
}

