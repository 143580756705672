.contact {
  &-line {
    display: flex;
    margin-bottom: 10px;
  }
  &-line-icon {
    margin-right: 10px;
  }
  &-line-text {
    font-size: 1,2em;
    & > a, a:visited {
      color: black; 
    }
  }
}

.contact-icon-group {
  display: flex;
}

.contact-icon-cell {
  flex: 0 0 50px;
  margin-right: 20px;
}

.contact-icon {
  background-size: cover;
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.researchgate {
  background-image: url("../images/ResearchGate_icon_SVG.svg");
}

.googlescholar {
  background-image: url("../images/Google_Scholar_logo.svg");
}

.inspirehep {
  background-image: url("https://pbs.twimg.com/profile_images/1234490198618185730/YDsnDGEQ.jpg");
}