.icons {
  cursor: pointer;
  transition: all .2s;
}

.icons:hover {
  color: rgb(107, 107, 107);
  transform: scale(1.2);
}

.eearnorwaylogo {
  min-height: 68px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("../images/EEANORWAYgrants-inverted.svg");
  background-position: center;
  margin-top: 20px;
  margin-bottom: 20px;
  transform: scale(1);
}

.ncnlogo {
  width: 150px;
  height: 80px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("../images/ncn-inverted.svg");
  background-position: center;
  margin-right: 10px;
  transform: scale(0.8);
}

.dptlogo {
  width: 150px;
  height: 80px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("../images/KFT_logo.png");
  background-position: center;
  margin-right: 10px;
  transform: scale(0.8);
}

.universitylogo {
  width: 200px;
  height: 80px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("../images/PWR_logo.svg");
  background-position: center;
  margin-right: 10px;
  // margin-left: 20px;
  transform: scale(0.8);
}