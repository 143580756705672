.footer {
  position: fixed;
  z-index: 20;
  display: flex;
  width: 100%;
  bottom: 0;
  align-items: center;
  min-height: 2em;
  background-color: rgba(37, 37, 37);
  font-size: 13px;
  color: white;
}

.logos {
  flex: 1 0 250px;
  display: flex;
  justify-content: space-between;
}

.designedby {
  flex: 0 1 250px;
  text-align: right;
  padding-right: 20px;
  & > a, a:visited {
    color: white;
    text-decoration: none;
  
  }
  font-size: 10px;
  margin-top: 20px;
}

