body {
  font-family: "Lexend Deca";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0px;
}

* {
  margin: 0;
}

code {
  font-family: "Lexend Deca";
}

a, a:active, a:visited {
  text-decoration: underline;
}

ul {
  list-style-type: none;
  padding: 0;
}

h2 {
  font-size: 40px;
  margin-bottom: 20px;
}

h3 {
  font-size: 22px;
  margin-bottom: 10px;
}

.image-member {
  width: 200px;
  float: right;
  margin: 0px 0px 25px 25px;
}

.member-wrapper {
  position: relative;
  margin-top: 80px;
}

.language_wrapper {
  width: 20px;
  height: 20px;
  margin-bottom: 10px;
  border-radius: 100px;
  outline: 1px solid black;
  & > img {
    height: 100%;
  }
}