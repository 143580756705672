.publication-list-element {
  margin-bottom: 50px;
}

.publication-title {
  margin-bottom: 50px;
}

.publication-link {
  color: rgba($color: #000000, $alpha: 1.0);
  font-size: 19px;
  hyphens: none;
}

.publication-authors {
  margin: 5px 0px;
  font-size: 13px;
}

.publication-fran {
  text-decoration: underline;
}

.publication-general {
  color: rgba($color: #000000, $alpha: 1.0);
}

.career-list-element {
  margin-bottom: 15px;
  &-academic {
    max-width: 70%;
    margin-bottom: 15px;
  }
}

.funding-group {
  display: flex;
  margin-bottom: 30px;
}

.funding-group-text {
  display: flex;
  flex-direction: column;
  max-width: 700px;
  flex: 1 1 70%;
  justify-content: center;
  margin-right: 30px;
}

.funding-text-past {
  max-width: 100%;
  flex: 1 1 100%;
}

.funding-group-title {
  margin-bottom: 5px;
}

.funding-group-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0 1 100px;
  & > a {
    margin-bottom: 10px;
  }
  & > a > img {
    min-width: 207px;
    width: 30%;
  }
}