.upper-disclaimer-wrapper {
  display: flex;
  justify-content: space-between;
  & > img {
    height: 74px;
  }
}

.lower-disclaimer-wrapper {
  text-align: justify;
  font-style: italic;
  font-size: 14px;
  &-images {
    width: 30%;
    float: right;
    margin: 0px 0px 15px 15px;
    display: flex;
    flex-direction: column;
    & > img {
      width: 100%;
      margin-bottom: 10px;
    }
    & > p {
      font-size: 10px;
    }
  }
}

.disclaimer-wrapper {

}

.disclaimer-slogan {
  font-size: 25px;
  font-weight: 800;
  text-align: end;
  &-green {
    color: rgb(32, 209, 127);
  };
  &-blue {
    color: rgb(0, 48, 150);
  }
  &-red {
    color: rgb(255, 0, 22);
  }
}

.separator {
  margin-top: 20px;
  margin-bottom: 20px;
}