@media only screen and (max-width: 769px) {
  h2 {
    font-size: 20px;
  }

  h3 {
    font-size: 14px;
  }

  p, div {
    font-size: 13px;
  }

  .section:first-of-type {
    margin-top: 0;
  }

  .section__wrapper {
  padding-left: 10%;
  padding-right: 10%;
  }

  .section {
    padding-left: 1em;
    padding-right: 1em;
  }

  .burgermenu {
    display: block;
    position: fixed;
    top: 20px;
    right: 20px;
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, .3);
    cursor: pointer;
  }
  .header {
    flex-direction: column;
    position: fixed;
    transition: all .5s;
  }
  .header__up {
    top: -301px;
  }
  .header__down {
    top: 0px;
  }
  .header__link {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .section-home {
    margin-bottom: 80px;
    padding: 0;
    min-height: calc(100vh);
  }

  .funding-group-current {
    flex-direction: column;
  }

  .funding-text-current {
    margin-bottom: 20px;
  }

  .footer {
    display: none;
  }

  .gototop {
    bottom: 20px;
  }

  .title-page {
    bottom: 120px;
    width: 93%;
    font-size: 30px;
  }

  .funding-group-image {
    flex-direction: row;
    justify-content: start;
    & > a {
      flex: 0 0 130px;
    }
    & > a > img {
      min-width: 110px;
      width: 20%;
    }
  }

  .publication-authors {
    font-size: 12px;
  }

  .publication-list-element {
    margin-bottom: 20px;
  }

  .disclaimer-slogan {
    font-size: 15px;
    font-weight: 800;
  };

  .upper-disclaimer-wrapper {
    & > img {
      height: 32px;
    }
  }

  .image-member {
    width: 100px;
  }

  .publication-link {
    font-size: 13px;
  }

  .lower-disclaimer-wrapper {
    & > p {
      font-size: 10px !important;
    }
    &-images {
      & > p {
        font-size: 8px;
      }
    }
  }

  .link__logo {
    height: 0;
    display: none;
  }

  .country_chooser {
    top: 20px;
  }
  

  }